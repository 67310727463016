export default {
  ABOUT: 'bevezetés',
  TOKENOMICS: 'Tokenomika',
  STAKING: 'karózás',
  'HOW TO BUY': 'Hogyan vásároljunk',
  ROADMAP: 'útiterv',
  FAQ: 'Gyakran Ismételt Kérdések',
  'BUY NOW': 'vásároljon most',
  'ELONXCAT is the real': 'Az ELONXCAT valódi',
  ELONMUSK: 'Elon Musk',
  'SpaceX and X': 'SpaceX és',
  'meme project': 'mém projekt',
  'Advanced Web3 Ecosystem': 'Fejlett Web3 ökoszisztéma',
  'Potential of project': 'Projekt potenciál',
  'Market cap Target': 'megcélzott piaci kapitalizáció',
  Investors: 'befektetők',
  'WHITE PAPER': 'fehér könyv',
  AUDIT: 'Köszönöm',
  'Presale will start soon. Join the Presale':
    'Hamarosan indul az előértékesítés. Vegyen részt az elővételben',
  'TOKEN DISTRIBUTION DATE': 'Token forgalmazási dátuma',
  Raised: 'Felvett összeg',
  'ELONXCAT sold': 'ELONXCAT értékesítési mennyiség',
  BUY: 'vásárlás',
  'MULTI BRIDGE UTILITY': 'Többszörös híd segédprogram',
  'Total Supply': 'teljes kínálat',
  'ELONXCAT ACHIEVEMENTS': 'ELONXCAT teljesítmény',
  'Ecosystem Percentage': 'ökoszisztéma arány',
  'ELONXCAT QUEST GAME': 'ELONXCAT Quest játék',
  'ELONXCAT GAME PORTAL SOON': 'Hamarosan érkezik az ELONXCAT játékportál',
  'NFT MARKET PLACE': 'NFT Marketplace',
  'Unique collections': 'egyedi gyűjtemény',
  'Launch at the same time as the project':
    'A projekttel egy időben jelent meg',
  'NFT Box and Airdrop': 'NFT doboz és airdrop',
  'DISCOVER NFT': 'Fedezze fel az NFT-ket',
  'AI-Powerde Content': 'AI-alapú tartalom',
  'AI Image Generator': 'AI képgenerátor',
  'AI Chat Bot': 'AI chat bot',
  'AI Code': 'AI kód',
  'COIN SWAP': 'Érmecsere',
  'ELONXCAT ALLOCAT STASTISTICS': 'ELONXCAT kiosztási statisztikák',
  'ELONXCAT STAKING SERVICE IS COMING SOON':
    'Az ELONXCAT Staking Service hamarosan elérhető',
  'WELCOME TO ELONXCAT STAKING': 'Üdvözöljük az ELONXCAT Stakingben',
  'WITHDRAW STAKED TOKENS': 'Vedd vissza a megtett jelzőket',
  'STAKED BALANCE': 'Staking Balance',
  'YOUR STAKEABLE': 'Egyenleg tétbe helyezhető',
  'TOTAL STAKED': 'teljes tét',
  'ESTIMATED REWARDS': 'várható jutalom',
  'Rewards rate is dynamic': 'A jutalmazási arány dinamikus',
  Monthly: 'havi',
  Daily: 'pillant',
  'CURRENT REWARDS': 'aktuális jutalom',
  'TOTAL REWARDS': 'teljes jutalom',
  'CLAIM REWARDS': 'kártérítési igény',
  'TOTAL SUPPLY': 'teljes kínálat',
  'OUR PARTNERS and BACKERS': 'Partnereink és szponzoraink',
  'ELONXCAT TEAM': 'ELONXCAT csapat',
  Founder: 'alapító',
  'Creative Director': 'kreatív igazgató',
  'Community manger': 'közösségi menedzser',
  'CONNECT YOUR WALLET': 'Pénztárca csatlakozás',
  'ENTER PRESALE': 'Részvétel az értékesítés előtt',
  'CLAIM ELONXCAT': 'ELONXCAT számlázás',
  'ROAD MAP': 'útiterv',
  DEVELOPMENT: 'fejlesztés',
  'MEME DOMINATION': 'Mém adományozás',
  'FREQUENTLY ASKED QUESTIONS': 'Gyakran Ismételt Kérdések',
  'What is a presale': 'Mi az előértékesítés?',
  'What is ELONXCAT': 'Mi az ELONXCAT?',
  'How do I participate in the presale': 'Hogyan vehetek részt az elővételben?',
  'When do I receive my presale tokens':
    'Mikor kapom meg az értékesítés előtti tokeneimet?',
  'What is ELONXCAT staking': 'Mi az ELONXCAT tét?',
  'How do I stake my tokens': 'Hogyan tehetem meg a tokeneimet?',
  'I staked my tokens during presale':
    'Tokeneket tettem meg az előértékesítés során, mikor igényelhetem őket?',
  'STAY CONNECTED': 'maradjon kapcsolatban',
  'PRIVACY POLICY': 'Adatvédelmi szabályzat',
  'TERMS and CONDITIONS': 'Felhasználási feltételek',
  'PRESALE ALLOCATION': 'Eladás előtti kiosztás',
  AIRDROP: 'földre száll',
  PROJECTFUNDS: 'projektfinanszírozás',
  LIQUIDITY: 'likviditás',
  MARKETING: 'marketing',
  EXCHANGES: 'csere',
  'ECOSYSTEM FUNDS': 'ökoszisztéma alapok',
  EXPANSION: 'terjeszkedés',
  'HELP CENTER': 'Súgóközpont',
  "If it doesn't work well on mobile": 'Ha nem működik jól mobilon',
  'please try using PC': 'próbálja meg számítógépen használni',

  texts: {
    txt_1:
      'Saját X nevű technológiánk támogatja ezt a többláncot. Az X támogatja a lánc migrációs funkcionalitást, lehetővé téve az ELONXCAT birtokosok számára, hogy szabadon mozogjanak a vezető kriptovaluta láncokon.',
    txt_2:
      'Az ELONXCAT egy többláncú hálózatra épülő mém token, amely átíveli a BNB láncot, az Ethereumot, a Polygont, a Solanát és a Tront.',
    txt_3:
      'Az ELONXCAT arra törekszik, hogy egyesítse a kriptovaluta mémek közösségét, és úttörővé váljon a többláncú segédprogramokban.',
    txt_4:
      'Célunk, hogy nevünk minden DEX-en, minden CEX-en és mindenhol máshol tündököljön.',
    txt_5: 'Kihasználva',
    txt_6: 'Az X a jövőben tovább bővíti ezeket a többláncokat.',
  },

  'PRESALE TIERS': 'értékesítés előtti szakasz',

  tiers: {
    tier_txt_1:
      'A kezdeti előértékesítési ár 0,0000009 USD/ülés, amely 20 nap múlva Tier2-re változik.',
    tier_txt_2:
      'Ennek a szintnek az ára az előértékesítéstől számítva 0,000001 USD, amely 20 nap múlva Tier3-ra változik.',
    tier_txt_3:
      'Ennek a szintnek az ára az előértékesítéstől számítva 0,000005 USD, amely 20 nap múlva a végső árára változik.',
    tier_txt_4:
      'Ennek a szintnek az előértékesítési ára 0,000009 USD, amely az ELONXCAT előértékesítésének befejezésével ér véget ezen az áron.',
    tier_txt_5:
      'Az ELONXCAT célja, hogy a legjobb DEX legyen a BSC-n olyan funkciókkal, mint a token csereügyletek, a likviditási poolok és a hozamgazdálkodás.',
    tier_txt_6:
      'Az ELONXCAT Quest egyesíti a játékot és a kriptovalutát, lehetőséget adva, hogy Lope tokeneket szerezzen küldetések és rejtvények révén a blokklánc világában.',
    tier_txt_7:
      'Az ELONXCAT NFT projekt egyesíti a digitális művészetet az ELONXCAT memecoinokkal, hogy egyedi, hasznosságban gazdag gyűjteményeket biztosítson a közösség elkötelezettségének fokozása érdekében.',
    tier_txt_8:
      'Az ELONXCAT a pénzügyet és a kreativitást egy felhasználóbarát platformon egyesíti az AI integrálásával a csevegéshez, a kereskedési tippekhez, a mémek létrehozásához és sok máshoz.',
    tier_txt_9:
      'Az ELONXCAT Quest egy blokkláncos játék az ELONXCAT Coin ökoszisztémán belül, amely ötvözi a játékot és a kriptovalutát egy digitális világban, amelyet a macska kabalája ihletett. A küldetések, rejtvények és PVP/PVE kihívások révén a játékosok Lope tokeneket szerezhetnek kalandok, stratégiák és közösségi interakciók révén. A játék célja, hogy megismertesse a felhasználókkal a blokkláncot és közösséget építsen a többjátékos funkciókon keresztül.',
    tier_txt_10:
      'Az ELONXCAT Coin NFT Marketplace egyedülálló kollekcióval rendelkezik, különleges és egyedi funkciókkal.',
    tier_txt_11:
      'Az ELONXCAT Coin NFT-k a projekttel együtt az összes globális piacon, valamint a projekt saját piacán is megjelennek.',
    tier_txt_12:
      'A piac tartalmaz egy nagy airdrop programot, amely az NFT doboz és a token előzetes értékesítése során aktiválódik.',
    tier_txt_13:
      'Hozzon létre SEO-optimalizált blogokat, értékesítési e-maileket és egyebeket... Takarítson meg időt és érjen el nagyszerű eredményeket. Végül van egy igazán használható íróeszköz.',
    tier_txt_14:
      'Képzeld el, miről álmodsz. Készítsen képeket szöveggel. Az AI képgenerátorunkkal azonnal lenyűgöző képeket készíthet művészi innovációjához.',
    tier_txt_15:
      'Egyszerűen válassza ki asszisztensét, és csevegjen egy barátságos csevegőbottal, hogy ötleteket generáljon, javítsa tartalmait, és néha még nevetgéljen is.',
    tier_txt_16:
      'Készen állsz villámgyors kódírásra? Könnyedén kódolhat az AI kódgenerátorral: Azonnali megoldásokat kínál programozási igényeire.',
    tier_txt_17:
      'Az ELONXCAT Swap célja, hogy a Binance Smart Chain (BSC) vezető decentralizált tőzsdei (DEX) platformja legyen.',
    tier_txt_18:
      'Az ELONXCAT Swap zökkenőmentes token csereügyleteket biztosít, likviditási poolokat biztosít, és olyan innovatív funkciókat vezet be, mint például a hozamgazdálkodás és a befektetés a likviditás fokozása, a tisztességes kereskedés biztosítása és az ELONXCAT közösség általános növekedésének elősegítése érdekében.',
    tier_txt_19:
      'Az ELONXCAT token jutalmak kiosztása két éven keresztül történik 708,72 $ELONXCAT Ethereum blokkonként, és a követelés aktiválása után igényelhető.',
    tier_txt_20:
      'Csatlakoztassa pénztárcáját másodpercek alatt a Metamask vagy a Trust Wallet segítségével.',
    tier_txt_21:
      'Döntse el, mennyi ELONXCAT dollárt szeretne vásárolni.  Vásárolhat USDT-vel, valamint BNB-vel, ETH-val és USDT-vel.',
    tier_txt_22:
      'Amint az előértékesítés véget ért, ugyanazon a pénztárcán és láncon keresztül igényelheti $ELONXCAT tokenjeit, amelyeket korábban vásárolt.',
    tier_txt_23: 'ELONMUSK születése és',
    tier_txt_24: 'Közösségépítés Token generálás Közösségi csatornák Születés',
    tier_txt_25: 'KYC & Audit Marketing Pump Megkezdődik az előértékesítés',
    tier_txt_26:
      'Marketing indítása CoinMarketCap  Jelentkezés CoinGecko jelentkezése ElonXCAT NFT frissítés ElonXCAT játékfejlesztés Staking frissítés',
    tier_txt_27:
      'Első DEX-lista  DEXTools frissítés  ElonXCAT AI frissítés Automatikus írás',
    tier_txt_28:
      'Folyamatos marketing Közösségi ösztönzők Partnerségek más platformokkal A birtokosok célszáma: több mint 1 millió',
    tier_txt_29:
      'Az előértékesítés egyedülálló lehetőséget kínál a befektetőknek, hogy új tokeneket vagy kriptovalutákat vásároljanak azok hivatalos bevezetése előtt. Ez a korai hozzáférési szakasz lehetővé teszi számunkra, hogy már a kezdet kezdetén kamatoztassuk a projektben rejlő lehetőségeket.',
    tier_txt_30:
      'Az ELONXCAT egy igazán fejlett, több láncból álló mém érme, és valójában az ELONMUSK & Speciális Web3 ökoszisztéma: Staking | DEX | AI | játékok | NFT piac.',
    tier_txt_31:
      'Az elővételben a BNB láncon lehet részt venni. Csatlakoztassa a Best Walletet vagy egy másik pénztárcát a fenti értékesítés előtti widgethez, és foglaljon le $ELONXCAT tokeneket BNB, ETH vagy USDT segítségével.',
    tier_txt_32:
      'Ha az értékesítés előtti vásárlás befejeződött, ugyanazt a pénztárcát használhatja a tokenek igénylésére az első DEX-bejegyzés előtt. A számlázási és listázási dátumokat közösségi oldalainkon közöljük.',
    tier_txt_33:
      'A kockázás az a cselekmény, amikor tokeneket küldenek egy intelligens szerződéshez, hogy zárolják őket egy bizonyos időtartamra. Ez lehetővé teszi, hogy idővel több $ELONXCAT tokent szerezzen dinamikus árfolyamon. Az ELONXCAT staking hamarosan elérhető lesz a BNB hálózatán. További információért kattintson a „Staking” lehetőségre a navigációs sávban.',
    tier_txt_34:
      'A $ELONXCAT téthez látogasson el ide: https://ELONXCAT/en/staking A tét csak a BNB-n vásárolt $ELONXCAT esetén lehetséges. BNB dollárra lesz szüksége a pénztárcájában, hogy feldolgozza a tétlevonási és kockáztatási jutalomigényeket.',
    tier_txt_35:
      'Ha $ELONXCAT téttel rendelkezik, legalább 14 napos megszolgálási időszakot kell várnia az igényléssel. A tét csak az Ethereumon vásárolt BNB dollárral lehetséges. BNB dollárra lesz szüksége a pénztárcájában, hogy feldolgozza a tétlevonási és kockáztatási jutalomigényeket.',
    tier_txt_36:
      'A kockázás az a cselekmény, amikor tokeneket küldenek egy intelligens szerződéshez, hogy zárolják őket egy bizonyos időtartamra. Ez lehetővé teszi, hogy idővel több $ELONXCAT tokent szerezzen dinamikus árfolyamon. Az ELONXCAT staking hamarosan elérhető lesz a BNB hálózatán. További információkért kattintson a „Staking” lehetőségre a navigációs sávban.',
    tier_txt_37:
      'A $ELONXCAT téthez látogasson el ide: https://ELONXCAT/en/staking A tét csak a BNB-n vásárolt $ELONXCAT esetén lehetséges. BNB dollárra lesz szüksége a pénztárcájában, hogy feldolgozza a tétlevonási és tét-jutalomigényeket.',
    tier_txt_38:
      'Ha $ELONXCAT téttel rendelkezik, legalább 14 napos megszolgálási időszakot kell várnia az igényléssel. A tét csak az Ethereumon vásárolt BNB dollárral lehetséges. BNB dollárra lesz szüksége a pénztárcájában, hogy feldolgozza a tétlevonási és kockáztatási jutalomigényeket.',
    tier_txt_39:
      'ELONXCAT IRODA PANAMA: 43. emelet, Óceánia BusinessPlaza, Tower 2000, Punta Colon Street, Urbanisación Punta Pacifica, Panama City, Panama',
    tier_txt_40:
      'Jogi nyilatkozat: Az ELONXCAT egy mém érme, értéke kizárólag az ELONXCAT mémek egyetemes kulturális tőkéjén alapul. A kriptovaluta értéke emelkedhet vagy csökkenhet. Óvatosan fektessen be.',
  },
  TOKENBURNINGPLAN: 'TOKEN ÉGÉSI TERV',
  OFFICIALELONXCATVIDEO: 'HIVATALOS ELONXCAT VIDEÓ',
  steps: {
    step_title_1: 'Tegyen kriptovalutát a tárcájába',
    step_title_2: 'Válassza ki a hálózatot',
    step_title_3: 'Csatlakoztassa a tárcáját',
    step_title_4: 'Vásároljon tokeneket',
    step_title_5: 'Ellenőrizze az EXC$ tokent a tárcájában.',
    step_title_6: 'Élvezze a kripto utazását az ELONXCAT-tel!',
    step_sub_title_1:
      'Kérem, helyezzen BNB-t vagy USDT-t a kriptovaluta tárcájába (pl. MetaMask).',
    step_sub_title_2:
      'Ügyeljen arra, hogy a tárca blokklánc hálózatát BSC-re állítsa.',
    step_sub_title_3:
      'Keresse meg a ‘csatlakoztatás tárcát’ gombot az előértékesítési widgetben, és csatlakoztassa a kívánt tárcát.',
    step_sub_title_4:
      'Adja meg a BNB vagy USDT mennyiségét, amelyet $EXC-ra szeretne cserélni, és erősítse meg a tranzakciót a tárcájában.',
    step_sub_title_5:
      'Ha nem látja az EXC-t a tárcájában, kérjük, adja hozzá a tokent. A token szerződés címe: 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a.',
    step_sub_title_6:
      'Az ELONXCAT csapata az ELONXCAT-ot a legjobb meme coin projektté fejleszti. Élvezzük együtt a kripto utazást a csapatunkkal!',
    how_to_buy_txt_1: 'A ‘HOGYAN VÁSÁROLJUNK $EXC’ videó készen áll.',
    how_to_buy_txt_2:
      'Kattintson a gombra, hogy megtekintse a ‘HOGYAN VÁSÁROLJUNK’-ot több nyelven!',
  },
  tokens_burning_plan_description1: 'A tőzsdén való lista után,',
  tokens_burning_plan_description2:
    'Csapatunk fokozatosan elégeti a tokenek több mint 90%-át.',
  tokens_burning_plan_description3:
    'A tokenek teljes kínálata folyamatosan csökken, és az ár növekedni fog.',
  tokens_burning_plan_description4:
    'A tokenek teljes kínálata folyamatosan csökken, és az ár növekedni fog.',
};
