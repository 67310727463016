export default {
  ABOUT: 'введение',
  TOKENOMICS: 'Токеномика',
  STAKING: 'ставка',
  'HOW TO BUY': 'Как купить',
  ROADMAP: 'дорожная карта',
  FAQ: 'Часто задаваемые вопросы',
  'BUY NOW': 'купить сейчас',
  'ELONXCAT is the real': 'ELONXCAT реален',
  ELONMUSK: 'Илон Маск',
  'SpaceX and X': 'SpaceX и',
  'meme project': 'мем-проект',
  'Advanced Web3 Ecosystem': 'Расширенная экосистема Web3',
  'Potential of project': 'Потенциал проекта',
  'Market cap Target': 'целевая рыночная капитализация',
  Investors: 'инвесторы',
  'WHITE PAPER': 'белая книга',
  AUDIT: 'Спасибо',
  'Presale will start soon. Join the Presale':
    'Скоро начнется предпродажа. Примите участие в предпродаже',
  'TOKEN DISTRIBUTION DATE': 'Дата распределения токенов',
  Raised: 'Сумма собрана',
  'ELONXCAT sold': 'Объем продаж ELONXCAT',
  BUY: 'покупка',
  'MULTI BRIDGE UTILITY': 'Утилита с несколькими мостами',
  'Total Supply': 'общее предложение',
  'ELONXCAT ACHIEVEMENTS': 'Производительность ELONXCAT',
  'Ecosystem Percentage': 'соотношение экосистем',
  'ELONXCAT QUEST GAME': 'ELONXCAT Квест-игра',
  'ELONXCAT GAME PORTAL SOON': 'Скоро появится игровой портал ELONXCAT',
  'NFT MARKET PLACE': 'Торговая площадка NFT',
  'Unique collections': 'уникальная коллекция',
  'Launch at the same time as the project': 'Выпущен одновременно с проектом',
  'NFT Box and Airdrop': 'NFT-бокс и раздача',
  'DISCOVER NFT': 'Изучите NFT',
  'AI-Powerde Content': 'Контент на основе искусственного интеллекта',
  'AI Image Generator': 'Генератор изображений AI',
  'AI Chat Bot': 'чат-бот с искусственным интеллектом',
  'AI Code': 'AI-код',
  'COIN SWAP': 'Обмен монет',
  'ELONXCAT ALLOCAT STASTISTICS': 'Статистика распределения ELONXCAT',
  'ELONXCAT STAKING SERVICE IS COMING SOON':
    'Скоро появится сервис ставок ELONXCAT',
  'WELCOME TO ELONXCAT STAKING': 'Добро пожаловать в ставку ELONXCAT',
  'WITHDRAW STAKED TOKENS': 'Вывести застейканные токены',
  'STAKED BALANCE': 'Ставка баланса',
  'YOUR STAKEABLE': 'Баланс доступен для стейкинга',
  'TOTAL STAKED': 'общая ставка',
  'ESTIMATED REWARDS': 'ожидаемое вознаграждение',
  'Rewards rate is dynamic': 'Коэффициент вознаграждения является динамическим',
  Monthly: 'ежемесячно',
  Daily: 'взглянуть мельком',
  'CURRENT REWARDS': 'текущая награда',
  'TOTAL REWARDS': 'общая награда',
  'CLAIM REWARDS': 'требование компенсации',
  'TOTAL SUPPLY': 'общее предложение',
  'OUR PARTNERS and BACKERS': 'Наши партнеры и спонсоры',
  'ELONXCAT TEAM': 'Команда ELONXCAT',
  Founder: 'основатель',
  'Creative Director': 'креативный директор',
  'Community manger': 'менеджер сообщества',
  'CONNECT YOUR WALLET': 'Подключение кошелька',
  'ENTER PRESALE': 'Предпродажное участие',
  'CLAIM ELONXCAT': 'ELONXCAT Биллинг',
  'ROAD MAP': 'дорожная карта',
  DEVELOPMENT: 'разработка',
  'MEME DOMINATION': 'Пожертвование мемов',
  'FREQUENTLY ASKED QUESTIONS': 'Часто задаваемые вопросы',
  'What is a presale': 'Что такое предварительная продажа?',
  'What is ELONXCAT': 'Что такое ELONXCAT?',
  'How do I participate in the presale':
    'Как мне принять участие в предпродаже?',
  'When do I receive my presale tokens':
    'Когда я получу свои токены предпродажи?',
  'What is ELONXCAT staking': 'Что такое ставка ELONXCAT?',
  'How do I stake my tokens': 'Как мне поставить свои токены?',
  'I staked my tokens during presale':
    'Я поставил токены во время предпродажи, когда я смогу их получить?',
  'STAY CONNECTED': 'оставаться на связи',
  'PRIVACY POLICY': 'политика конфиденциальности',
  'HOW TO BUY': 'Как купить',
  'TERMS and CONDITIONS': 'Условия эксплуатации',
  'PRESALE ALLOCATION': 'Предпродажное распределение',
  AIRDROP: 'раздача по воздуху',
  PROJECTFUNDS: 'финансирование проекта',
  LIQUIDITY: 'ликвидность',
  MARKETING: 'маркетинг',
  EXCHANGES: 'обмен',
  'ECOSYSTEM FUNDS': 'экосистемные фонды',
  EXPANSION: 'расширение',
  'HELP CENTER': 'Центр поддержки',
  "If it doesn't work well on mobile":
    'Если на мобильном устройстве работает некорректно',
  'please try using PC': 'попробуйте использовать ПК',

  texts: {
    txt_1:
      'Наша собственная технология под названием X поддерживает эту мультичейн. X поддерживает функцию миграции цепочки, позволяя держателям ELONXCAT свободно перемещаться между ведущими цепочками криптовалют.',
    txt_2:
      'ELONXCAT — это мем-токен, построенный на мультичейн-сети, охватывающей цепочку BNB, Ethereum, Polygon, Solana и Tron.',
    txt_3:
      'ELONXCAT стремится объединить сообщество криптовалютных мемов и стать пионером в области мультичейновых утилит.',
    txt_4:
      'Наша цель — чтобы наше имя сияло на каждой DEX, каждой CEX и везде.',
    txt_5: 'Использование',
    txt_6: 'X продолжит расширять эти мультичейны в будущем.',
  },
  'PRESALE TIERS': 'предпродажная стадия',

  tiers: {
    tier_txt_1:
      'Начальная цена предварительной продажи составляет 0,0000009 долларов США за место, которая изменится на Tier2 через 20 дней.',
    tier_txt_2:
      'Цена этого уровня установлена на уровне 0,000001 доллара США с предварительной продажи, которая изменится на Tier3 через 20 дней.',
    tier_txt_3:
      'Цена этого уровня установлена на уровне 0,000005 доллара США с предварительной продажи, которая изменится на конечную цену через 20 дней.',
    tier_txt_4:
      'Цена предварительной продажи этого уровня составляет 0,000009 доллара США, которая завершится с окончанием предварительной продажи ELONXCAT по этой цене.',
    tier_txt_5:
      'ELONXCAT стремится стать лучшей DEX на BSC с такими функциями, как обмен токенов, пулы ликвидности и выращивание доходности.',
    tier_txt_6:
      'ELONXCAT Quest сочетает в себе игры и криптовалюту, предоставляя вам возможность зарабатывать токены Lope, выполняя квесты и головоломки в мире блокчейна.',
    tier_txt_7:
      'Проект ELONXCAT NFT объединяет цифровое искусство с мемкоинами ELONXCAT, чтобы предоставить уникальные, полезные предметы коллекционирования для повышения вовлеченности сообщества.',
    tier_txt_8:
      'ELONXCAT объединяет финансы и творчество в удобной для пользователя платформе, интегрируя искусственный интеллект для общения, торговых советов, создания мемов и многого другого.',
    tier_txt_9:
      'ELONXCAT Quest — это блокчейн-игра в экосистеме ELONXCAT Coin, объединяющая игры и криптовалюту в цифровом мире, вдохновленном талисманом кошки. Благодаря квестам, головоломкам и испытаниям PVP/PVE игроки могут зарабатывать жетоны Лопе посредством приключений, стратегии и социального взаимодействия. Цель игры — познакомить пользователей с блокчейном и создать сообщество с помощью многопользовательских функций.',
    tier_txt_10:
      'ELONXCAT Coin NFT Marketplace имеет уникальную коллекцию со специальными и уникальными функциями.',
    tier_txt_11:
      'NFT монеты ELONXCAT будут котироваться вместе с проектом на всех мировых рынках, а также на собственном рынке проекта.',
    tier_txt_12:
      'Рынок включает в себя крупную программу раздачи, которая будет активирована во время предварительной продажи коробок NFT и токенов.',
    tier_txt_13:
      'Создавайте SEO-оптимизированные блоги, рекламные электронные письма и многое другое... Экономьте время и получайте отличные результаты. Наконец, появился действительно полезный инструмент для разработки.',
    tier_txt_14:
      'Визуализируйте то, о чем вы мечтаете. Создание изображений с текстом. Мгновенно создавайте потрясающие изображения для своих художественных инноваций с помощью нашего генератора изображений с искусственным интеллектом.',
    tier_txt_15:
      'Просто выберите своего помощника и пообщайтесь с дружелюбным чат-ботом, чтобы генерировать идеи, улучшать свой контент, а иногда даже рассмешить.',
    tier_txt_16:
      'Готовы писать код с молниеносной скоростью? Легко кодируйте с помощью AI Code Generator: обеспечивает мгновенные решения для ваших потребностей в программировании.',
    tier_txt_17:
      'ELONXCAT Swap стремится стать ведущей платформой децентрализованной биржи (DEX) в Binance Smart Chain (BSC).',
    tier_txt_18:
      'ELONXCAT Swap обеспечивает плавный обмен токенами, предоставляет пулы ликвидности и представляет инновационные функции, такие как сбор урожая и стейкинг, для повышения ликвидности, обеспечения честной торговли и содействия общему росту сообщества ELONXCAT.',
    tier_txt_19:
      'Распределение вознаграждений в токенах ELONXCAT будет производиться в течение двух лет по цене 708,72 $ELONXCAT за блок Ethereum, и их можно будет запросить после активации заявки.',
    tier_txt_20:
      'Подключите свой кошелек за считанные секунды с помощью Metamask или Trust Wallet.',
    tier_txt_21:
      'Решите, сколько долларов ELONXCAT вы хотите приобрести.   Вы можете совершать покупки за USDT вместе с BNB, ETH и USDT.',
    tier_txt_22:
      'После завершения предварительной продажи вы сможете запросить свои токены $ELONXCAT через тот же кошелек и цепочку, которые вы использовали для покупки.',
    tier_txt_23: 'Рождение ELONMUSK &',
    tier_txt_24:
      'Создание сообщества  Генерация токенов  Социальные каналы  Рождение',
    tier_txt_25:
      'KYC и аудит  Маркетинговый насос  Начинается предварительная продажа',
    tier_txt_26:
      'Начать маркетинг  CoinMarketCap   Подать заявку  Подать заявку на участие в CoinGecko  Обновление ElonXCAT NFT  Разработка игр ElonXCAT  Обновление о ставках',
    tier_txt_27:
      'Первый листинг DEX   Обновление DEXTools   Обновление ElonXCAT AI  Автоматическая запись',
    tier_txt_28:
      'Постоянный маркетинг  Стимулы сообщества  Партнерство с другими платформами  Целевое количество владельцев: более 1 миллиона',
    tier_txt_29:
      'Предварительные продажи предоставляют инвесторам уникальную возможность приобрести новые токены или криптовалюты до их официального запуска. Этот этап раннего доступа позволяет нам с самого начала извлечь выгоду из потенциала проекта.',
    tier_txt_30:
      'ELONXCAT — это действительно продвинутая монета-мем с несколькими цепочками, которая на самом деле является ELONMUSK & Продвинутая экосистема Web3: стейкинг | ДЕКС | ИИ | игры | NFT-рынок.',
    tier_txt_31:
      'Вы можете принять участие в предварительной продаже в сети BNB. Подключите Best Wallet или другой кошелек к виджету предпродажи выше и зарезервируйте токены $ELONXCAT за BNB, ETH или USDT.',
    tier_txt_32:
      'После завершения предпродажной покупки вы сможете использовать тот же кошелек для получения своих токенов до первого листинга на DEX. Даты выставления счетов и листинга будут объявлены в наших аккаунтах в социальных сетях.',
    tier_txt_33:
      'Стейкинг — это отправка токенов в смарт-контракт для их блокировки на определенный период времени. Это позволит вам зарабатывать больше токенов $ELONXCAT с течением времени с динамичной скоростью. Стейкинг ELONXCAT скоро будет доступен в сети BNB. Для получения дополнительной информации нажмите «Стейкинг» на панели навигации.',
    tier_txt_34:
      'Чтобы сделать стейкинг $ELONXCAT, посетите здесь: https://ELONXCAT/en/stake Ставка возможна только при покупке $ELONXCAT на BNB. Вам понадобится $BNB в вашем кошельке для обработки заявок на дестейкинг и вознаграждение за стейкинг.',
    tier_txt_35:
      'Если вы сделали ставку на $ELONXCAT, вам необходимо подождать не менее 14 дней, прежде чем заявить о ней. Ставка возможна только при покупке BNB на Ethereum. Вам понадобится $BNB в вашем кошельке для обработки заявок на дестейкинг и вознаграждение за стейкинг.',
    tier_txt_36:
      'Стейкинг — это отправка токенов в смарт-контракт для их блокировки на определенный период времени. Это позволит вам зарабатывать больше токенов $ELONXCAT с течением времени с динамичной скоростью. Стейкинг ELONXCAT скоро будет доступен в сети BNB. Для получения дополнительной информации нажмите «Ставка» на панели навигации.',
    tier_txt_37:
      'Чтобы сделать ставку на $ELONXCAT, посетите здесь: https://ELONXCAT/en/saving Ставка возможна только при покупке $ELONXCAT на BNB. Вам понадобится $BNB в вашем кошельке для обработки заявок на дестейкинг и вознаграждение за стейкинг.',
    tier_txt_38:
      'Если вы сделали ставку на $ELONXCAT, вам необходимо подождать не менее 14 дней, прежде чем заявить о ней. Стейкинг возможен только при покупке BNB на Ethereum. Вам понадобится $BNB в вашем кошельке для обработки заявок на дестейкинг и вознаграждение за стейкинг.',
    tier_txt_39:
      'ОФИС ELONXCAT в ПАНАМЕ: 43-й этаж, Oceania Business  Plaza, Tower 2000, Punta Colon Street, Urbanisación Punta  Pacifica, Панама-Сити, Панама',
    tier_txt_40:
      'Отказ от ответственности: ELONXCAT — это монета-мем, ее ценность основана исключительно на универсальном культурном капитале мемов ELONXCAT.  Стоимость криптовалюты может увеличиваться или уменьшаться. Инвестируйте осторожно.',
  },

  TOKENBURNINGPLAN: 'ПЛАН СЖИГАНИЯ ТОКЕНОВ',
  OFFICIALELONXCATVIDEO: 'ОФИЦИАЛЬНОЕ ВИДЕО ELONXCAT',
  steps: {
    step_title_1: 'Пополните криптовалюту в вашем кошельке',
    step_title_2: 'Выберите сеть',
    step_title_3: 'Подключите кошелек',
    step_title_4: 'Купите токены',
    step_title_5: 'Проверьте токен EXC$ в своем кошельке',
    step_title_6: 'Наслаждайтесь своим криптопутешествием с ELONXCAT!',
    step_sub_title_1:
      'Пожалуйста, пополните BNB или USDT в ваш криптокошелек (например, MetaMask).',
    step_sub_title_2:
      'Убедитесь, что вы переключили блокчейн-сеть кошелька на BSC.',
    step_sub_title_3:
      'Найдите кнопку ‘подключить кошелек на виджете предварительной продажи’ и подключите свой предпочитаемый кошелек.',
    step_sub_title_4:
      'Введите количество BNB или USDT, которое вы хотите обменять на $EXC и подтвердите транзакцию в своем кошельке.',
    step_sub_title_5:
      'Если вы не видите EXC в своем кошельке, добавьте токен. Адрес контракта токена: 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a',
    step_sub_title_6:
      'Команда ELONXCAT будет развивать ELONXCAT в лучший проект мемкойна. Давайте наслаждаться криптопутешествием вместе с нашей командой!',
    how_to_buy_txt_1: "Видео 'КАК КУПИТЬ $EXC' готово.",
    how_to_buy_txt_2:
      "Нажмите кнопку, чтобы посмотреть 'КАК КУПИТЬ' на разных языках!",
  },
  tokens_burning_plan_description1: 'После листинга на бирже,',
  tokens_burning_plan_description2:
    'Наша команда постепенно сожжет более 90% токенов.',
  tokens_burning_plan_description3:
    'Общее количество токенов будет продолжать уменьшаться, и цена будет расти.',
  tokens_burning_plan_description4:
    'Общее количество токенов будет продолжать уменьшаться, и цена будет расти.',
};
