export default {
  ABOUT: 'introducción',
  TOKENOMICS: 'Tokenómica',
  STAKING: 'apostando',
  'HOW TO BUY': 'como comprar',
  ROADMAP: 'hoja de ruta',
  FAQ: 'Preguntas frecuentes',
  'BUY NOW': 'comprar ahora',
  'ELONXCAT is the real': 'ELONXCAT es real',
  ELONMUSK: 'Elon Musk',
  'SpaceX and X': 'SpaceX y',
  'meme project': 'proyecto meme',
  'Advanced Web3 Ecosystem': 'Ecosistema Web3 avanzado',
  'Potential of project': 'Potencial del proyecto',
  'Market cap Target': 'capitalización de mercado objetivo',
  Investors: 'inversores',
  'WHITE PAPER': 'libro blanco',
  AUDIT: 'gracias',
  'Presale will start soon. Join the Presale':
    'La preventa comienza pronto. Participa en la preventa',
  'TOKEN DISTRIBUTION DATE': 'Fecha de distribución del token',
  Raised: 'Cantidad recaudada',
  'ELONXCAT sold': 'Volumen de ventas de ELONXCAT',
  BUY: 'compra',
  'MULTI BRIDGE UTILITY': 'Utilidad de puente múltiple',
  'Total Supply': 'suministro total',
  'ELONXCAT ACHIEVEMENTS': 'Rendimiento de ELONXCAT',
  'Ecosystem Percentage': 'proporción de ecosistemas',
  'ELONXCAT QUEST GAME': 'Juego de búsqueda ELONXCAT',
  'ELONXCAT GAME PORTAL SOON': 'Próximamente el portal de juegos ELONXCAT',
  'NFT MARKET PLACE': 'Mercado NFT',
  'Unique collections': 'colección única',
  'Launch at the same time as the project':
    'Lanzado simultáneamente con el proyecto.',
  'NFT Box and Airdrop': 'Caja NFT y lanzamiento aéreo',
  'DISCOVER NFT': 'Explorar NFT',
  'AI-Powerde Content': 'Contenido basado en IA',
  'AI Image Generator': 'Generador de imágenes de IA',
  'AI Chat Bot': 'robot de chat de IA',
  'AI Code': 'código IA',
  'COIN SWAP': 'Intercambio de monedas',
  'ELONXCAT ALLOCAT STASTISTICS': 'Estadísticas de asignación de ELONXCAT',
  'ELONXCAT STAKING SERVICE IS COMING SOON':
    'Próximamente el servicio de replanteo ELONXCAT',
  'WELCOME TO ELONXCAT STAKING': 'Bienvenido a ELONXCAT Stake',
  'WITHDRAW STAKED TOKENS': 'Retirar tokens apostados',
  'STAKED BALANCE': 'Saldo de apuesta',
  'YOUR STAKEABLE': 'Saldo disponible para apostar',
  'TOTAL STAKED': 'apuesta total',
  'ESTIMATED REWARDS': 'recompensa esperada',
  'Rewards rate is dynamic': 'La relación de recompensa es dinámica',
  Monthly: 'mensual',
  Daily: 'mirada',
  'CURRENT REWARDS': 'recompensa actual',
  'TOTAL REWARDS': 'recompensa total',
  'CLAIM REWARDS': 'reclamo de compensación',
  'TOTAL SUPPLY': 'suministro total',
  'OUR PARTNERS and BACKERS': 'Nuestros socios y patrocinadores',
  'ELONXCAT TEAM': 'Equipo ELONXCAT',
  Founder: 'fundador',
  'Creative Director': 'director creativo',
  'Community manger': 'administrador de la comunidad',
  'CONNECT YOUR WALLET': 'Conexión de billetera',
  'ENTER PRESALE': 'Participación en preventa',
  'CLAIM ELONXCAT': 'Facturación ELONXCAT',
  'ROAD MAP': 'hoja de ruta',
  DEVELOPMENT: 'desarrollo',
  'MEME DOMINATION': 'Donación de memes',
  'FREQUENTLY ASKED QUESTIONS': 'Preguntas frecuentes',
  'What is a presale': '¿Qué es una preventa?',
  'What is ELONXCAT': '¿Qué es ELONXCAT?',
  'How do I participate in the presale': '¿Cómo participo en la preventa?',
  'When do I receive my presale tokens':
    '¿Cuándo recibiré mis tokens de preventa?',
  'What is ELONXCAT staking': '¿Qué está apostando ELONXCAT?',
  'How do I stake my tokens': '¿Cómo apuesto mis tokens?',
  'I staked my tokens during presale':
    'Aposté tokens durante la preventa, ¿cuándo puedo reclamarlos?',
  'STAY CONNECTED': 'mantente conectado',
  'PRIVACY POLICY': 'política de privacidad',
  'HOW TO BUY': 'como comprar',
  'TERMS and CONDITIONS': 'Condiciones de uso',
  'PRESALE ALLOCATION': 'Asignación de preventa',
  AIRDROP: 'entrega por paracaídas',
  PROJECTFUNDS: 'financiación del proyecto',
  LIQUIDITY: 'liquidez',
  MARKETING: 'marketing',
  EXCHANGES: 'intercambio',
  'ECOSYSTEM FUNDS': 'fondos ecosistémicos',
  EXPANSION: 'expansión',
  'HELP CENTER': 'Centro de ayuda',
  "If it doesn't work well on mobile": 'Si no funciona bien en el móvil',
  'please try using PC': 'por favor intenta usar una PC',

  texts: {
    txt_1:
      'Nuestra propia tecnología llamada X soporta esta multicadena. X admite la funcionalidad de migración en cadena, lo que permite a los titulares de ELONXCAT moverse libremente a través de las principales cadenas de criptomonedas.',
    txt_2:
      'ELONXCAT es un token de meme creado en una red multicadena que abarca la cadena BNB, Ethereum, Polygon, Solana y Tron.',
    txt_3:
      'ELONXCAT se esfuerza por unir a la comunidad de memes de criptomonedas y convertirse en pionero en utilidades multicadena.',
    txt_4:
      'Nuestro objetivo es que nuestro nombre brille en cada DEX, cada CEX y en cualquier otro lugar.',
    txt_5: 'Utilizando',
    txt_6: 'X continuará expandiendo estas multicadenas en el futuro.',
  },
  'PRESALE TIERS': 'etapa de preventa',

  tiers: {
    tier_txt_1:
      'El precio inicial de preventa es de 0,0000009 USD por asiento, que cambiará a Tier2 después de 20 días.',
    tier_txt_2:
      'El precio de este nivel se establece en 0,000001 USD desde la preventa, que cambiará a Tier3 después de 20 días.',
    tier_txt_3:
      'El precio de este nivel se establece en 0,000005 USD desde la preventa, que cambiará al precio final después de 20 días.',
    tier_txt_4:
      'El precio de preventa de este nivel es de 0,000009 USD, que finalizará con el fin de la preventa ELONXCAT a este precio.',
    tier_txt_5:
      'ELONXCAT pretende ser el mejor DEX en BSC con características como intercambios de tokens, fondos comunes de liquidez y cultivo de rendimiento.',
    tier_txt_6:
      'ELONXCAT Quest combina juegos y criptomonedas, brindándote la oportunidad de ganar tokens Lope a través de misiones y acertijos en el mundo blockchain.',
    tier_txt_7:
      'El proyecto ELONXCAT NFT combina el arte digital con las memecoins ELONXCAT para proporcionar objetos coleccionables únicos y de gran utilidad para mejorar la participación de la comunidad.',
    tier_txt_8:
      'ELONXCAT combina finanzas y creatividad en una plataforma fácil de usar al integrar IA para chatear, consejos comerciales, creación de memes y más.',
    tier_txt_9:
      'ELONXCAT Quest es un juego blockchain dentro del ecosistema ELONXCAT Coin, que combina juegos y criptomonedas en un mundo digital inspirado en la mascota del gato. Con misiones, acertijos y desafíos PVP/PVE, los jugadores pueden ganar fichas Lope a través de aventuras, estrategias e interacción social. El juego tiene como objetivo presentar a los usuarios la cadena de bloques y construir una comunidad a través de funciones multijugador.',
    tier_txt_10:
      'ELONXCAT Coin NFT Marketplace tiene una colección única con características especiales y únicas.',
    tier_txt_11:
      'Los NFT de ELONXCAT Coin se incluirán junto con el proyecto en todos los mercados globales, así como en el propio mercado del proyecto.',
    tier_txt_12:
      'El mercado incluye un gran programa de lanzamiento aéreo que se activará durante la preventa de cajas NFT y tokens.',
    tier_txt_13:
      'Cree blogs optimizados para SEO, correos electrónicos de ventas y más... Ahorre tiempo y obtenga excelentes resultados. Finalmente, existe una herramienta de creación verdaderamente útil.',
    tier_txt_14:
      'Visualiza lo que sueñas. Crea imágenes con texto. Cree instantáneamente imágenes impresionantes para su innovación artística con nuestro generador de imágenes de IA.',
    tier_txt_15:
      'Simplemente seleccione su asistente y charle con un amigable chatbot para generar ideas, mejorar su contenido y, a veces, incluso hacer reír.',
    tier_txt_16:
      '¿Listo para escribir código a la velocidad del rayo? Codifique fácilmente con AI Code Generator: proporciona soluciones instantáneas a sus necesidades de programación.',
    tier_txt_17:
      'ELONXCAT Swap pretende ser la plataforma líder de intercambio descentralizado (DEX) en Binance Smart Chain (BSC).',
    tier_txt_18:
      'ELONXCAT Swap proporciona intercambios de tokens sin interrupciones, proporciona fondos de liquidez e introduce características innovadoras como cultivo de rendimiento y apuestas para mejorar la liquidez, garantizar un comercio justo y promover el crecimiento general de la comunidad ELONXCAT.',
    tier_txt_19:
      'La distribución de las recompensas de tokens ELONXCAT se realizará durante dos años a razón de 708,72 $ ELONXCAT por bloque de Ethereum, y se podrá reclamar una vez que se active el reclamo.',
    tier_txt_20:
      'Conecte su billetera en segundos usando Metamask o Trust Wallet.',
    tier_txt_21:
      'Decide cuánto $ELONXCAT quieres comprar.   Puede comprar con USDT junto con BNB, ETH y USDT.',
    tier_txt_22:
      'Una vez que finalice la preventa, podrás reclamar tus tokens $ELONXCAT a través de la misma billetera y cadena que usaste para comprar.',
    tier_txt_23: 'El nacimiento de ELONMUSK y',
    tier_txt_24:
      'Construcción de comunidad  Generación de tokens  Canales sociales  Nacimiento',
    tier_txt_25: 'KYC y auditoría  Marketing Pump  Comienza la preventa',
    tier_txt_26:
      'Comience a comercializar  CoinMarketCap   Solicitar  Solicitar CoinGecko  Actualización de ElonXCAT NFT  Desarrollo de juegos ElonXCAT  Actualización de apuestas',
    tier_txt_27:
      'Primer listado de DEX   Actualización de DEXTools   Actualización de ElonXCAT AI  Grabación automática',
    tier_txt_28:
      'Marketing continuo  Incentivos comunitarios  Asociaciones con otras plataformas  Número objetivo de titulares: más de 1 millón',
    tier_txt_29:
      'Las preventas brindan una oportunidad única para que los inversores compren nuevos tokens o criptomonedas antes de su lanzamiento oficial. Esta fase de acceso temprano nos permite capitalizar el potencial del proyecto desde el principio.',
    tier_txt_30:
      'ELONXCAT es una moneda meme de múltiples cadenas verdaderamente avanzada y en realidad es ELONMUSK & Ecosistema Web3 avanzado: replanteo | DEX | IA | juegos | Mercado NFT.',
    tier_txt_31:
      'Puedes participar en la preventa en la cadena BNB. Conecte Best Wallet u otra billetera al widget de preventa anterior y reserve tokens $ELONXCAT con BNB, ETH o USDT.',
    tier_txt_32:
      'Una vez que se complete su compra de preventa, puede usar la misma billetera para reclamar sus tokens antes de la primera lista de DEX. Las fechas de facturación y cotización se anunciarán en nuestras cuentas de redes sociales.',
    tier_txt_33:
      'Stake es el acto de enviar tokens a un contrato inteligente para bloquearlos durante un período de tiempo determinado. Esto le permite ganar más tokens $ELONXCAT con el tiempo a un ritmo dinámico. La participación en ELONXCAT pronto estará disponible en la red BNB. Para obtener más información, haga clic en "Stake" en la barra de navegación.',
    tier_txt_34:
      'Para apostar $ELONXCAT, visite aquí: https://ELONXCAT/en/stake Solo es posible apostar con $ELONXCAT comprado en BNB. Necesitará $BNB en su billetera para procesar las reclamaciones de recompensas de retirada de apuestas y apuestas.',
    tier_txt_35:
      'Si ha apostado $ ELONXCAT, debe esperar un período de adquisición de derechos de al menos 14 días antes de reclamarlo. Solo es posible apostar con $BNB comprados en Ethereum. Necesitará $BNB en su billetera para procesar las reclamaciones de recompensas de retirada de apuestas y apuestas.',
    tier_txt_36:
      'Stake es el acto de enviar tokens a un contrato inteligente para bloquearlos durante un período de tiempo determinado. Esto le permite ganar más tokens $ELONXCAT con el tiempo a un ritmo dinámico. La participación en ELONXCAT pronto estará disponible en la red BNB. Para obtener más información, haga clic en "Stake" en la barra de navegación.',
    tier_txt_37:
      'Para apostar $ELONXCAT, visite aquí: https://ELONXCAT/en/stake Solo es posible apostar con $ELONXCAT comprado en BNB. Necesitará $BNB en su billetera para procesar las reclamaciones de recompensas de retirada de apuestas y apuestas.',
    tier_txt_38:
      'Si ha apostado $ ELONXCAT, debe esperar un período de adquisición de derechos de al menos 14 días antes de reclamarlo. Solo es posible apostar con $BNB comprados en Ethereum. Necesitará $BNB en su billetera para procesar las reclamaciones de recompensas de retirada de apuestas y apuestas.',
    tier_txt_39:
      'OFICINA ELONXCAT PANAMA: Piso 43, Oceania Business Plaza, Torre 2000, Calle Punta Colón, Urbanización Punta  Pacifica, Ciudad de Panamá, Panamá',
    tier_txt_40:
      'Descargo de responsabilidad: ELONXCAT es una moneda meme, su valor se basa únicamente en el capital cultural universal de los memes ELONXCAT.  El valor de una criptomoneda puede subir o bajar. Invierta con cuidado.',
  },
  TOKENBURNINGPLAN: 'PLAN DE QUEMA DE TOKENS',
  OFFICIALELONXCATVIDEO: 'VIDEO OFICIAL DE ELONXCAT',
  steps: {
    step_title_1: 'Deposita criptomonedas en tu billetera',
    step_title_2: 'Selecciona la Red',
    step_title_3: 'Conecta tu Billetera',
    step_title_4: 'Compra Tokens',
    step_title_5: 'Revisa el token EXC$ en tu billetera.',
    step_title_6: '¡Disfruta tu viaje cripto con ELONXCAT!',
    step_sub_title_1:
      'Por favor, deposita BNB o USDT en tu billetera de criptomonedas (por ejemplo, MetaMask).',
    step_sub_title_2:
      'Asegúrate de cambiar la red blockchain de tu billetera a BSC.',
    step_sub_title_3:
      'Encuentra el botón ‘Conectar Billetera’ en el widget de preventa y conecta tu billetera preferida.',
    step_sub_title_4:
      'Ingresa la cantidad de BNB o USDT que deseas intercambiar por $EXC y confirma la transacción en tu billetera.',
    step_sub_title_5:
      'Si no puedes ver EXC en tu billetera, por favor añade el token. La dirección del contrato del token es 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a.',
    step_sub_title_6:
      'El equipo de ELONXCAT desarrollará ELONXCAT como el mejor proyecto de meme coin. ¡Disfrutemos juntos el viaje cripto con nuestro equipo!',
    how_to_buy_txt_1: 'El video ‘CÓMO COMPRAR $EXC’ está listo.',
    how_to_buy_txt_2:
      '¡Haz clic en el botón para ver ‘CÓMO COMPRAR’ en varios idiomas!',
  },
  tokens_burning_plan_description1: 'Después de ser listado en el intercambio,',
  tokens_burning_plan_description2:
    'Nuestro equipo quemará gradualmente más del 90% de los tokens.',
  tokens_burning_plan_description3:
    'La oferta total de tokens continuará disminuyendo y el precio aumentará.',
  tokens_burning_plan_description4:
    'La oferta total de tokens continuará disminuyendo y el precio aumentará.',
};
