import './all.css';
import './default.css';
import './all_bak.css';
import './main_20241016.css';
import './Notice.css';
import { useEffect, useState } from 'react';
import { useTranslate } from '../hooks/useTranslate';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from '../context/TranslationContext';
import angleDown from '../assets/images/flags/angle-down.svg';

// flags images
import English from '../assets/images/flags/English.svg';
import Arabic from '../assets/images/flags/Arabic.svg';
import Bulgarian from '../assets/images/flags/Bulgarian.svg';
import Chinese from '../assets/images/flags/Chinese.svg';
import Czech from '../assets/images/flags/Czech.svg';
import Dutch from '../assets/images/flags/Dutch.svg';
import French from '../assets/images/flags/French.svg';
import German from '../assets/images/flags/German.svg';
import Greek from '../assets/images/flags/Greek.svg';
import Hungarian from '../assets/images/flags/Hungarian.svg';
import Indonesian from '../assets/images/flags/Indonesian.svg';
import Italian from '../assets/images/flags/Italian.svg';
import Japanese from '../assets/images/flags/Japanese.svg';
import Korean from '../assets/images/flags/Korean.svg';
import Polish from '../assets/images/flags/Polish.svg';
import Portuguese from '../assets/images/flags/Portuguese.svg';
import Romanian from '../assets/images/flags/Romanian.svg';
import Russian from '../assets/images/flags/Russian.svg';
import Slovak from '../assets/images/flags/Slovak.svg';
import Spanish from '../assets/images/flags/Spanish.svg';
import Thai from '../assets/images/flags/Thai.svg';
import Turkish from '../assets/images/flags/Turkish.svg';
import Vietnamese from '../assets/images/flags/Vietnamese.svg';
import axios from 'axios';

function Notice() {
  const [notices, setNotices] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileLanguageOpen, setIsMobileLanguageOpen] = useState(false);
  const [openIndexes, setOpenIndexes] = useState([]);

  const t = useTranslate();

  const { currentLanguage, setLanguage } = useTranslation();

  const handleLanguageChange = (languageCode) => {
    console.log('Selected language:', languageCode);
    setLanguage(languageCode);
    // Implement your language change logic here
  };

  const toggleMobileLanguage = () => {
    setIsMobileLanguageOpen(!isMobileLanguageOpen);
  };

  const handleMobileLanguageSelect = (language) => {
    handleLanguageChange(language.code);
    setIsMobileLanguageOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleNotice = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  const fetchNotices = async () => {
    try {
      const response = await axios.get('https://elonxcat.com/api/notices');

      if (response.status === 200) {
        const reversedNotices = response.data.items.reverse();

        setNotices(reversedNotices);
        console.log('Notices:', reversedNotices);
      }
    } catch (error) {
      console.error('Error fetching notices:', error);
    }
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  const languages = [
    { code: 'en', name: 'English', flag: English },
    { code: 'ar', name: 'Arabic', flag: Arabic },
    { code: 'bg', name: 'Bulgarian', flag: Bulgarian },
    { code: 'cn', name: 'Chinese (simplified)', flag: Chinese },
    { code: 'cnt', name: 'Chinese (traditional)', flag: Chinese },
    { code: 'cz', name: 'Czech', flag: Czech },
    { code: 'nl', name: 'Dutch', flag: Dutch },
    { code: 'fr', name: 'French', flag: French },
    { code: 'de', name: 'German', flag: German },
    { code: 'el', name: 'Greek', flag: Greek },
    { code: 'hu', name: 'Hungarian', flag: Hungarian },
    { code: 'id', name: 'Indonesian', flag: Indonesian },
    { code: 'it', name: 'Italian', flag: Italian },
    { code: 'jp', name: 'Japanese', flag: Japanese },
    { code: 'kr', name: 'Korean', flag: Korean },
    { code: 'pl', name: 'Polish', flag: Polish },
    { code: 'pr', name: 'Portuguese', flag: Portuguese },
    { code: 'rm', name: 'Romanian', flag: Romanian },
    { code: 'ru', name: 'Russian', flag: Russian },
    { code: 'sl', name: 'Slovak', flag: Slovak },
    { code: 'es', name: 'Spanish', flag: Spanish },
    { code: 'th', name: 'Thai', flag: Thai },
    { code: 'tu', name: 'Turkish', flag: Turkish },
    { code: 'vu', name: 'Vietnamese', flag: Vietnamese },
  ];

  return (
    <div id='wrap'>
      <header>
        <div className='container-2'>
          <div className='hd-wrap'>
            <div className='left-area'>
              <div id='logo'>
                <h1>
                  <a href='/'>
                    <img
                      src='https://elonxcat.com/assets/images/logo.png'
                      alt='ELONXCAT'
                    />
                  </a>
                </h1>
              </div>
              <div id='main-menu'>
                <ul>
                  <li>
                    <a href='/notice'>{t('NOTICE')}</a>
                  </li>
                  <li>
                    <a href='/#'>{t('ABOUT')}</a>
                  </li>
                  <li>
                    <a href='/#section9'>{t('TOKENOMICS')}</a>
                  </li>
                  <li>
                    <a href='/#section10'>{t('STAKING')}</a>
                  </li>
                  <li>
                    <a href='/#section15'>{t('HOW TO BUY')}</a>
                  </li>
                  <li>
                    <a href='/#section16'>{t('ROADMAP')}</a>
                  </li>
                  <li>
                    <a href='/#section17'>{t('FAQ')}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='right-area'>
              <div id='util-menu'>
                <ul>
                  <li>
                    <a
                      href='https://x.com/elonxcat'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src='https://elonxcat.com/assets/images/icons/twitter_icon.png'
                        alt='elonxcat_twitter'
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://t.me/ELONXCAT_PORTAL'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src='https://elonxcat.com/assets/images/icons/telegram_icon.png'
                        alt='elonxcat_telegram'
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.youtube.com/@ELONXCAT'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src='https://elonxcat.com/assets/images/icons/youtube.png'
                        alt='elonxcat_youtube'
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '50%',
                          border: '1px solid #fff',
                        }}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.instagram.com/elonxcat'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src='https://elonxcat.com/assets/images/icons/instagram.png'
                        alt='elonxcat_instagram'
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '50%',
                          border: '1px solid #fff',
                        }}
                      />
                    </a>
                  </li>
                  <li className='buy-now'>
                    <button
                      type='button'
                      onClick={() =>
                        document
                          .getElementById('section1')
                          .scrollIntoView({ behavior: 'smooth' })
                      }
                    >
                      {t('BUY NOW')}
                    </button>
                  </li>
                </ul>
              </div>

              {/* language selector */}
              <LanguageSelector
                onLanguageChange={handleLanguageChange}
                languages={languages}
              />

              <div className={`mobile-menu ${isMenuOpen ? 'active' : ''}`}>
                <button
                  type='button'
                  className={`mobile-menu-btn ${isMenuOpen ? 'active' : ''}`}
                  onClick={toggleMenu}
                >
                  <span />
                  <span />
                  <span />
                </button>
                <div
                  className={`mobile-menu-wrap ${isMenuOpen ? 'show' : ''}`}
                  style={{
                    display: isMenuOpen ? 'block' : 'none',
                  }}
                >
                  <ul className='mobile-menu-list'>
                    <li>
                      <a href='/notice'>{t('NOTICE')}</a>
                    </li>
                    <li>
                      <a href='/#'>{t('ABOUT')}</a>
                    </li>
                    <li>
                      <a href='/#section9'>{t('TOKENOMICS')}</a>
                    </li>
                    <li>
                      <a href='/#section10'>{t('STAKING')}</a>
                    </li>
                    <li>
                      <a href='/#section15'>{t('HOW TO BUY')}</a>
                    </li>
                    <li>
                      <a href='/#section16'>{t('ROADMAP')}</a>
                    </li>
                    <li>
                      <a href='/#section17'>{t('FAQ')}</a>
                    </li>
                  </ul>
                  <ul className='mobile-util'>
                    <li>
                      <a
                        href='https://x.com/elonxcat'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src='https://elonxcat.com/assets/images/icons/twitter_icon.png'
                          alt='elonxcat_twitter'
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://t.me/ELONXCAT_PORTAL'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src='https://elonxcat.com/assets/images/icons/telegram_icon.png'
                          alt='elonxcat_telegram'
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.youtube.com/@ELONXCAT'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src='https://elonxcat.com/assets/images/icons/youtube.png'
                          alt='elonxcat_youtube'
                          style={{
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            border: '1px solid #fff',
                          }}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.instagram.com/elonxcat'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src='https://elonxcat.com/assets/images/icons/instagram.png'
                          alt='elonxcat_instagram'
                          style={{
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            border: '1px solid #fff',
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                  <div
                    className={`mobile-language ${
                      isMobileLanguageOpen ? 'active' : ''
                    }`}
                  >
                    <div
                      type='button'
                      className='language-btn'
                      onClick={toggleMobileLanguage}
                    >
                      <img
                        src={currentLanguage.flag}
                        alt={`elonxcat_${currentLanguage.name} flag`}
                        className='language-flag'
                      />
                      <span className='language-text'>
                        {currentLanguage.name}
                      </span>
                    </div>
                    <ul className='language-list'>
                      {languages.map((language) => (
                        <button
                          key={language.code}
                          type='button'
                          className='language-item'
                          onClick={() => handleMobileLanguageSelect(language)}
                        >
                          <img
                            src={language.flag}
                            alt={`elonxcat_${language.name} flag`}
                            className='language-flag'
                          />
                          <span>{language.name}</span>
                        </button>
                      ))}
                    </ul>
                  </div>
                  <button
                    type='button'
                    className='mobile-buy-now'
                    onClick={() =>
                      document
                        .getElementById('section1')
                        .scrollIntoView({ behavior: 'smooth' })
                    }
                  >
                    {t('BUY NOW')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id='contents'>
        <div className='section'>
          <div className='notice-container'>
            <h1 className='title'>Notice</h1>
            <div className='notice-wrapper'>
              {notices.map((notice, index) => (
                <div className='notice-item'>
                  <div key={index} className='notice'>
                    <div className='notice-left'>
                      <span className='notice-title'>{notice.title}</span>
                    </div>
                    <div className='notice-right'>
                      <span className='notice-date'>{notice.date}</span>
                    </div>
                    <button
                      type='button'
                      className='notice-spread'
                      onClick={() => toggleNotice(index)}
                    >
                      <img
                        src={angleDown}
                        alt='elonxcat_notice_spread'
                        style={{
                          transform: openIndexes.includes(index)
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                          transition: 'transform 0.3s',
                        }}
                      />
                    </button>
                  </div>
                  {openIndexes.includes(index) && (
                    <div className='notice-content'>
                      <p>{notice.content}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className='container'>
          <div className='footer-wrap'>
            <div className='ft-top'>
              <div className='sitemap-area'>
                <ul>
                  <li>
                    <a href='/#'>{t('ABOUT')}</a>
                  </li>
                  <li>
                    <a
                      href='/ELONCAT Privacy Policy.pdf'
                      target='_blank'
                      rel='noreferrer noopenner'
                    >
                      {t('PRIVACY POLICY')}
                    </a>
                  </li>
                  <li>
                    <a href='/#'>{t('HOW TO BUY')}</a>
                  </li>
                  <li>
                    <a
                      href='/ELONCAT Cookies Policy.pdf'
                      target='_blank'
                      rel='noreferrer noopenner'
                    >
                      COOKIES
                    </a>
                  </li>
                  <li>
                    <a href='/#'>{t('ROADMAP')}</a>
                  </li>
                  <li>
                    <a
                      href='/ELONCAT TERMS OF SERVICE.pdf'
                      target='_blank'
                      rel='noreferrer noopenner'
                    >
                      {t('TERMS and CONDITIONS')}
                    </a>
                  </li>
                  <li>
                    <a href='/#'>{t('FAQ')}</a>
                  </li>
                </ul>
              </div>
              <div className='ft-logo'>
                <img
                  src='https://elonxcat.com/assets/images/ft-logo.png'
                  alt='elonxcat'
                />
              </div>
              <div className='company-info'>
                <p>
                  {t('tiers.tier_txt_39')}
                  <br />
                  <a
                    href='mailto:contact@elonxcat.com'
                    className='__cf_email__'
                  >
                    contact@elonxcat.com
                  </a>
                </p>
              </div>
            </div>
            <div className='sponsored-text'>
              <p>
                SPONSORED BY COINNEWS. GET THE LATEST BITCOIN &amp; CRYPTO NEWS
              </p>
            </div>
            <div className='copyright'>
              <p>
                Copyright 2024 ELONXCAT. All Rights Reserved.
                <br />
                <br />
                <p
                  dangerouslySetInnerHTML={{ __html: t('tiers.tier_txt_40') }}
                ></p>{' '}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Notice;
