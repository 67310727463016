export default {
  ABOUT: 'giới thiệu',
  TOKENOMICS: 'Tokenomics',
  STAKING: 'đặt cược',
  'HOW TO BUY': 'Làm thế nào để mua hàng',
  ROADMAP: 'lộ trình',
  FAQ: 'Câu hỏi thường gặp',
  'BUY NOW': 'mua ngay bây giờ',
  'ELONXCAT is the real': 'ELONXCAT là có thật',
  ELONMUSK: 'Elon Musk',
  'SpaceX and X': 'SpaceX và',
  'meme project': 'dự án meme',
  'Advanced Web3 Ecosystem': 'Hệ sinh thái Web3 nâng cao',
  'Potential of project': 'Tiềm năng dự án',
  'Market cap Target': 'vốn hóa thị trường mục tiêu',
  Investors: 'nhà đầu tư',
  'WHITE PAPER': 'sổ trắng',
  AUDIT: 'Cảm ơn',
  'Presale will start soon. Join the Presale':
    'Việc bán trước sẽ sớm bắt đầu. Tham gia đợt bán trước',
  'TOKEN DISTRIBUTION DATE': 'Ngày phân phối mã thông báo',
  Raised: 'Số tiền huy động',
  'ELONXCAT sold': 'Khối lượng bán hàng ELONXCAT',
  BUY: 'mua',
  'MULTI BRIDGE UTILITY': 'Tiện ích nhiều cầu',
  'Total Supply': 'tổng cung',
  'ELONXCAT ACHIEVEMENTS': 'Hiệu suất ELONXCAT',
  'Ecosystem Percentage': 'tỷ lệ hệ sinh thái',
  'ELONXCAT QUEST GAME': 'Trò chơi nhiệm vụ ELONXCAT',
  'ELONXCAT GAME PORTAL SOON': 'Cổng game ELONXCAT sắp ra mắt',
  'NFT MARKET PLACE': 'Thị trường NFT',
  'Unique collections': 'bộ sưu tập độc đáo',
  'Launch at the same time as the project': 'Ra mắt đồng thời với dự án',
  'NFT Box and Airdrop': 'Hộp NFT và airdrop',
  'DISCOVER NFT': 'Khám phá NFT',
  'AI-Powerde Content': 'Nội dung dựa trên AI',
  'AI Image Generator': 'Trình tạo hình ảnh AI',
  'AI Chat Bot': 'Robot trò chuyện AI',
  'AI Code': 'Mã AI',
  'COIN SWAP': 'Trao đổi tiền xu',
  'ELONXCAT ALLOCAT STASTISTICS': 'Thống kê phân bổ ELONXCAT',
  'ELONXCAT STAKING SERVICE IS COMING SOON':
    'Dịch vụ đặt cược ELONXCAT sắp ra mắt',
  'WELCOME TO ELONXCAT STAKING': 'Chào mừng bạn đến với Đặt cược ELONXCAT',
  'WITHDRAW STAKED TOKENS': 'Rút token đã đặt cọc',
  'STAKED BALANCE': 'Số dư đặt cược',
  'YOUR STAKEABLE': 'Số dư có sẵn để đặt cọc',
  'TOTAL STAKED': 'tổng số tiền đặt cược',
  'ESTIMATED REWARDS': 'phần thưởng mong đợi',
  'Rewards rate is dynamic': 'Tỷ lệ phần thưởng linh hoạt',
  Monthly: 'hàng tháng',
  Daily: 'liếc nhìn',
  'CURRENT REWARDS': 'phần thưởng hiện tại',
  'TOTAL REWARDS': 'tổng phần thưởng',
  'CLAIM REWARDS': 'yêu cầu bồi thường',
  'TOTAL SUPPLY': 'tổng cung',
  'OUR PARTNERS and BACKERS': 'Đối tác và nhà tài trợ của chúng tôi',
  'ELONXCAT TEAM': 'Đội ELONXCAT',
  Founder: 'người sáng lập',
  'Creative Director': 'giám đốc sáng tạo',
  'Community manger': 'quản lý cộng đồng',
  'CONNECT YOUR WALLET': 'Kết nối ví',
  'ENTER PRESALE': 'Tham gia trước khi bán',
  'CLAIM ELONXCAT': 'Thanh toán ELONXCAT',
  'ROAD MAP': 'lộ trình',
  DEVELOPMENT: 'phát triển',
  'MEME DOMINATION': 'đóng góp meme',
  'FREQUENTLY ASKED QUESTIONS': 'Câu hỏi thường gặp',
  'What is a presale': 'Bán trước là gì?',
  'What is ELONXCAT': 'ELONXCAT là gì?',
  'How do I participate in the presale':
    'Làm cách nào để tham gia đợt bán trước?',
  'When do I receive my presale tokens':
    'Khi nào tôi sẽ nhận được mã thông báo bán trước của mình?',
  'What is ELONXCAT staking': 'Đặt cược ELONXCAT là gì?',
  'How do I stake my tokens': 'Làm cách nào để đặt cược mã thông báo của tôi?',
  'I staked my tokens during presale':
    'Tôi đã đặt cọc token trong đợt bán trước, khi nào tôi có thể nhận được chúng?',
  'STAY CONNECTED': 'duy trì kết nối',
  'PRIVACY POLICY': 'Chính sách bảo mật',
  'HOW TO BUY': 'Làm thế nào để mua hàng',
  ABOUT: 'giới thiệu',
  'TERMS and CONDITIONS': 'Điều khoản sử dụng',
  ROADMAP: 'lộ trình',
  'PRESALE ALLOCATION': 'Phân bổ trước khi bán',
  AIRDROP: 'airdrop',
  PROJECTFUNDS: 'tài trợ dự án',
  LIQUIDITY: 'tính thanh khoản',
  MARKETING: 'tiếp thị',
  EXCHANGES: 'trao đổi',
  'ECOSYSTEM FUNDS': 'quỹ hệ sinh thái',
  EXPANSION: 'mở rộng',
  'HELP CENTER': 'Trung tâm trợ giúp',
  "If it doesn't work well on mobile":
    'Nếu không hoạt động tốt trên điện thoại',
  'please try using PC': 'vui lòng thử sử dụng PC',

  texts: {
    txt_1:
      'Công nghệ riêng của chúng tôi có tên X hỗ trợ multichain này. X hỗ trợ chức năng di chuyển chuỗi, cho phép chủ sở hữu ELONXCAT di chuyển tự do trên các chuỗi tiền điện tử hàng đầu.',
    txt_2:
      'ELONXCAT là mã thông báo meme được xây dựng trên mạng đa chuỗi bao gồm chuỗi BNB, Ethereum, Polygon, Solana và Tron.',
    txt_3:
      'ELONXCAT cố gắng đoàn kết cộng đồng meme tiền điện tử và trở thành người tiên phong trong các tiện ích đa chuỗi.',
    txt_4:
      'Mục tiêu của chúng tôi là đưa tên tuổi của mình tỏa sáng trên mọi DEX, mọi CEX và mọi nơi khác.',
    txt_5: 'Sử dụng',
    txt_6: 'X sẽ tiếp tục mở rộng các multichain này trong tương lai.',
  },
  'PRESALE TIERS': 'giai đoạn trước khi bán',
  tiers: {
    tier_txt_1:
      'Giá bán trước chính thức ban đầu là 0,0000009 USD/ghế, sẽ tăng lên Tier2 sau 20 ngày.',
    tier_txt_2:
      'Giá của tầng này được thiết lập ở mức 0,000001 USD từ đợt bán trước, sẽ tăng lên Tier3 sau 20 ngày.',
    tier_txt_3:
      'Giá của tầng này được thiết lập ở mức 0,000005 USD từ đợt bán trước, sẽ tăng lên giá cuối cùng sau 20 ngày.',
    tier_txt_4:
      'Giá bán trước chính thức của tầng này là 0,000009 USD, sẽ kết thúc cùng với đợt bán trước ELONXCAT với mức giá này.',
    tier_txt_5:
      'ELONXCAT đặt mục tiêu trở thành DEX tốt nhất trên BSC với các tính năng như hoán đổi mã thông báo, nhóm thanh khoản và canh tác lợi nhuận.',
    tier_txt_6:
      'ELONXCAT Quest kết hợp trò chơi và tiền điện tử, mang đến cho bạn cơ hội kiếm được mã thông báo Lope thông qua các nhiệm vụ và câu đố trong thế giới blockchain.',
    tier_txt_7:
      'Dự án ELONXCAT NFT kết hợp nghệ thuật kỹ thuật số với memecoin ELONXCAT để cung cấp các bộ sưu tập độc đáo, giàu tiện ích nhằm tăng cường sự tham gia của cộng đồng.',
    tier_txt_8:
      'ELONXCAT kết hợp tài chính và sáng tạo trong một nền tảng thân thiện với người dùng bằng cách tích hợp AI để trò chuyện, mẹo giao dịch, tạo meme, v.v.',
    tier_txt_9:
      'ELONXCAT Quest là một trò chơi blockchain trong hệ sinh thái ELONXCAT Coin, kết hợp trò chơi và tiền điện tử trong thế giới kỹ thuật số lấy cảm hứng từ linh vật mèo. Với các nhiệm vụ, câu đố và thử thách PVP/PVE, người chơi có thể kiếm được token Lope thông qua cuộc phiêu lưu, chiến lược và tương tác xã hội. Trò chơi nhằm mục đích giới thiệu cho người dùng về blockchain và xây dựng cộng đồng thông qua các tính',
    tier_txt_10:
      'ELONXCAT Coin NFT Marketplace có một bộ sưu tập độc đáo với các tính năng đặc biệt và độc đáo.',
    tier_txt_11:
      'ELONXCAT Coin NFT sẽ được niêm yết cùng với dự án trên tất cả các thị trường toàn cầu cũng như trên thị trường riêng của dự án.',
    tier_txt_12:
      'Thị trường bao gồm một chương trình airdrop lớn sẽ được kích hoạt trong quá trình bán trước hộp NFT và mã thông báo.',
    tier_txt_13:
      'Tạo blog, email bán hàng, v.v. được tối ưu hóa cho SEO... Tiết kiệm thời gian và nhận được kết quả tuyệt vời. Cuối cùng, có một công cụ soạn thảo thực sự có thể sử dụng được.',
    tier_txt_14:
      'Hình dung những gì bạn mơ ước. Tạo hình ảnh bằng văn bản. Tạo ngay những hình ảnh tuyệt đẹp cho sự đổi mới nghệ thuật của bạn bằng trình tạo hình ảnh AI của chúng tôi.',
    tier_txt_15:
      'Chỉ cần chọn trợ lý của bạn và trò chuyện với một chatbot thân thiện để đưa ra ý tưởng, cải thiện nội dung của bạn và đôi khi còn mang lại tiếng cười.',
    tier_txt_16:
      'Sẵn sàng để viết mã với tốc độ cực nhanh? Viết mã dễ dàng với AI Code Generator: Cung cấp giải pháp tức thời cho nhu cầu lập trình của bạn.',
    tier_txt_17:
      'ELONXCAT Swap đặt mục tiêu trở thành nền tảng trao đổi phi tập trung (DEX) hàng đầu trên Binance Smart Chain (BSC).',
    tier_txt_18:
      'Hoán đổi ELONXCAT cung cấp các giao dịch hoán đổi mã thông báo liền mạch, cung cấp nhóm thanh khoản và giới thiệu các tính năng cải tiến như canh tác năng suất và đặt cược để nâng cao tính thanh khoản, đảm bảo giao dịch công bằng và thúc đẩy sự phát triển chung của cộng đồng ELONXCAT.',
    tier_txt_19:
      'Việc phân phối phần thưởng mã thông báo ELONXCAT sẽ được thực hiện trong hai năm với tỷ lệ 708,72 $ELONXCAT cho mỗi khối Ethereum và có thể được nhận sau khi yêu cầu được kích hoạt.',
    tier_txt_20:
      'Kết nối ví của bạn trong vài giây bằng Metamask hoặc Trust Wallet.',
    tier_txt_21:
      'Quyết định số lượng $ELONXCAT bạn muốn mua. <br /> Bạn có thể mua bằng USDT cùng với BNB, ETH và USDT.',
    tier_txt_22:
      'Sau khi đợt bán trước kết thúc, bạn có thể nhận mã thông báo $ELONXCAT của mình thông qua cùng một ví và chuỗi mà bạn đã sử dụng để mua.',
    tier_txt_23: 'Sự ra đời của ELONMUSK &',
    tier_txt_24:
      'Xây dựng cộng đồng<br /> Tạo token<br /> Kênh xã hội<br /> Sự ra đời',
    tier_txt_25: 'KYC & Kiểm toán<br /> Bơm tiếp thị<br /> Bắt đầu bán trước',
    tier_txt_26:
      'Bắt đầu tiếp thị<br /> CoinMarketCap <br /> Đăng ký<br /> Đăng ký CoinGecko<br /> Cập nhật ElonXCAT NFT<br /> Phát triển trò chơi ElonXCAT<br /> Cập nhật đặt cược',
    tier_txt_27:
      'Danh sách DEX đầu tiên <br /> Cập nhật DEXTools <br /> Cập nhật AI ElonXCAT<br /> Tự động ghi',
    tier_txt_28:
      'Tiếp thị liên tục<br /> Khuyến khích cộng đồng<br /> Hợp tác với các nền tảng khác<br /> Số lượng chủ sở hữu mục tiêu: 1 triệu+',
    tier_txt_29:
      'Bán trước mang lại cơ hội duy nhất cho các nhà đầu tư mua token hoặc tiền điện tử mới trước khi ra mắt chính thức. Giai đoạn truy cập sớm này cho phép chúng tôi tận dụng tiềm năng của dự án ngay từ đầu.',
    tier_txt_30:
      'ELONXCAT là một đồng meme đa chuỗi thực sự tiên tiến và thực sự là ELONMUSK & Hệ sinh thái Web3 nâng cao: Đặt cược | DEX | AI | trò chơi | thị trường NFT.',
    tier_txt_31:
      'Bạn có thể tham gia đợt bán trước trên chuỗi BNB. Kết nối Best Wallet hoặc một ví khác với tiện ích bán trước ở trên và dự trữ mã thông báo $ELONXCAT bằng BNB, ETH hoặc USDT.',
    tier_txt_32:
      'Sau khi quá trình mua trước khi bán của bạn hoàn tất, bạn có thể sử dụng cùng một ví để nhận mã thông báo của mình trước khi niêm yết DEX đầu tiên. Ngày thanh toán và niêm yết sẽ được công bố trên các tài khoản truyền thông xã hội của chúng tôi.',
    tier_txt_33:
      'Đặt cược là hành động gửi mã thông báo đến hợp đồng thông minh để khóa chúng trong một khoảng thời gian nhất định. Điều này cho phép bạn kiếm được nhiều token ELONXCAT hơn theo thời gian với tốc độ linh hoạt. Đặt cược ELONXCAT sẽ sớm có sẵn trên mạng BNB. Để biết thêm thông tin, hãy nhấp vào ‘Đặt cược’ trên thanh điều hướng.',
    tier_txt_34:
      'Để đặt cược $ELONXCAT, hãy truy cập vào đây: https://elonxcat/en/stakes Chỉ có thể đặt cược với $ELONXCAT được mua trên BNB. Bạn sẽ cần $BNB trong ví của mình để xử lý yêu cầu hủy đặt cược và yêu cầu phần thưởng đặt cược.',
    tier_txt_35:
      'Nếu bạn đã đặt cược $ELONXCAT, bạn phải đợi khoảng thời gian nhận quyền ít nhất 14 ngày trước khi yêu cầu số tiền đó. Chỉ có thể đặt cọc với $BNB được mua trên Ethereum. Bạn sẽ cần $BNB trong ví của mình để xử lý yêu cầu hủy đặt cược và yêu cầu phần thưởng đặt cược.',
    tier_txt_36:
      'Đặt cược là hành động gửi mã thông báo đến hợp đồng thông minh để khóa chúng trong một khoảng thời gian nhất định. Điều này cho phép bạn kiếm được nhiều token ELONXCAT hơn theo thời gian với tốc độ linh hoạt. Đặt cược ELONXCAT sẽ sớm có sẵn trên mạng BNB. Để biết thêm thông tin, hãy nhấp vào ‘Đặt cược’ trên thanh điều hướng.',
    tier_txt_37:
      'Để đặt cọc $ELONXCAT, hãy truy cập vào đây: https://elonxcat/en/stering Chỉ có thể đặt cược với $ELONXCAT được mua trên BNB. Bạn sẽ cần $BNB trong ví của mình để xử lý yêu cầu hủy đặt cược và yêu cầu phần thưởng đặt cược.',
    tier_txt_38:
      'Nếu bạn đã đặt cược $ELONXCAT, bạn phải đợi khoảng thời gian nhận quyền ít nhất 14 ngày trước khi yêu cầu số tiền đó. Chỉ có thể đặt cọc với $BNB được mua trên Ethereum. Bạn sẽ cần $BNB trong ví của mình để xử lý yêu cầu hủy đặt cược và yêu cầu phần thưởng đặt cược.',
    tier_txt_39:
      'VĂN PHÒNG ELONXCAT PANAMA: Tầng 43, Khu kinh doanh Châu Đại Dương<br />Plaza, Tower 2000, Phố Punta Colon, Urbanisación Punta<br /> Pacifica, Thành phố Panama, Panama',
    tier_txt_40:
      'Tuyên bố từ chối trách nhiệm: ELONXCAT là một đồng meme, giá trị của nó chỉ dựa trên vốn văn hóa toàn cầu của các meme ELONXCAT.<br /> Giá trị của tiền điện tử có thể tăng hoặc giảm. Đầu tư cẩn thận.',
  },
  TOKENBURNINGPLAN: 'KẾ HOẠCH ĐỐT TOKEN',
  OFFICIALELONXCATVIDEO: 'VIDEO CHÍNH THỨC ELONXCAT',
  steps: {
    step_title_1: 'Nạp tiền điện tử vào ví của bạn',
    step_title_2: 'Chọn mạng lưới',
    step_title_3: 'Kết nối ví',
    step_title_4: 'Mua token',
    step_title_5: 'Kiểm tra token EXC$ trong ví của bạn',
    step_title_6: 'Thưởng thức hành trình crypto của bạn với ELONXCAT!',
    step_sub_title_1:
      'Vui lòng nạp BNB hoặc USDT vào ví điện tử của bạn (ví dụ: MetaMask).',
    step_sub_title_2:
      'Hãy chắc chắn rằng bạn đã thay đổi mạng lưới blockchain của ví thành BSC.',
    step_sub_title_3:
      "Tìm nút 'kết nối ví' trong widget bán trước và kết nối ví bạn muốn.",
    step_sub_title_4:
      'Nhập số lượng BNB hoặc USDT bạn muốn đổi lấy $EXC và xác nhận giao dịch trong ví của bạn.',
    step_sub_title_5:
      'Nếu bạn không thấy EXC trong ví của mình, vui lòng thêm token. Địa chỉ hợp đồng token là 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a',
    step_sub_title_6:
      'Nhóm ELONXCAT sẽ phát triển ELONXCAT thành dự án meme coin tốt nhất. Hãy tận hưởng hành trình crypto của chúng ta cùng đội ngũ!',
    how_to_buy_txt_1: "Video 'CÁCH MUA $EXC' đã sẵn sàng.",
    how_to_buy_txt_2: "Nhấn vào nút để xem 'CÁCH MUA' bằng nhiều ngôn ngữ!",
  },
  tokens_burning_plan_description1: 'Sau khi niêm yết trên sàn,',
  tokens_burning_plan_description2:
    'Đội ngũ của chúng tôi sẽ đốt dần hơn 90% token.',
  tokens_burning_plan_description3:
    'Tổng cung cấp token sẽ tiếp tục giảm và giá trị sẽ tăng.',
  tokens_burning_plan_description4:
    'Tổng cung cấp token sẽ tiếp tục giảm và giá trị sẽ tăng.',
};
