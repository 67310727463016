import React from 'react';
import App from './App';
import Wrapper from './Wrapper';
import { BrowserRouter } from 'react-router-dom';

const Main = () => (
  // <React.StrictMode>
  <BrowserRouter>
    <Wrapper>
      <App />
    </Wrapper>
  </BrowserRouter>
  // </React.StrictMode>,
);

export default Main;
