export default {
  current_language: 'English',
  ABOUT: 'ABOUT',
  TOKENOMICS: 'TOKENOMICS',
  STAKING: 'STAKING',
  'HOW TO BUY': 'HOW TO BUY',
  ROADMAP: 'ROADMAP',
  FAQ: 'FAQ',
  'BUY NOW': 'BUY NOW',
  'ELONXCAT is the real': 'ELONXCAT is the real',
  ELONMUSK: 'ELONMUSK',
  'SpaceX and X': 'SpaceX & X',
  'meme project': 'meme project',
  'Advanced Web3 Ecosystem': 'Advanced Web3 Ecosystem',
  'Potential of project': 'Potential of project',
  'Market cap Target': 'Market cap Target',
  Investors: 'Investors',
  'WHITE PAPER': 'WHITE PAPER',
  AUDIT: 'AUDIT',
  'Presale will start soon. Join the Presale':
    'Presale will start soon. Join the Presale',
  'TOKEN DISTRIBUTION DATE': 'TOKEN DISTRIBUTION DATE',
  Raised: 'Raised',
  'ELONXCAT sold': 'ELONXCAT sold',
  BUY: 'BUY',
  'MULTI BRIDGE UTILITY': 'MULTI BRIDGE UTILITY',
  'Total Supply': 'Total Supply',
  'ELONXCAT ACHIEVEMENTS': 'ELONXCAT ACHIEVEMENTS',
  'Ecosystem Percentage': 'Ecosystem Percentage',
  'ELONXCAT GET TOKEN GAME': 'ELONXCAT GET TOKEN GAME',
  'ELONXCAT GAME PORTAL SOON': 'ELONXCAT GAME PORTAL SOON',
  'NFT MARKET PLACE': 'NFT MARKET PLACE',
  'Unique collections': 'Unique collections',
  'Launch at the same time as the project':
    'Launch at the same time as the project',
  'NFT Box and Airdrop': 'NFT Box and Airdrop',
  'DISCOVER NFT': 'DISCOVER NFT',
  'AI-Powerde Content': 'AI-Powerd Content',
  'AI Image Generator': 'AI Image Generator',
  'AI Chat Bot': 'AI Chat Bot',
  'AI Code': 'AI Code',
  'COIN SWAP': 'COIN SWAP',
  'ELONXCAT ALLOCAT STASTISTICS': 'ELONXCAT ALLOCAT STASTISTICS',
  'ELONXCAT STAKING SERVICE IS COMING SOON':
    'ELONXCAT STAKING SERVICE IS COMING SOON',
  'WELCOME TO ELONXCAT STAKING': 'WELCOME TO ELONXCAT STAKING',
  'WITHDRAW STAKED TOKENS': 'WITHDRAW STAKED TOKENS',
  'STAKED BALANCE': 'STAKED BALANCE',
  'YOUR STAKEABLE': 'YOUR STAKEABLE',
  'TOTAL STAKED': 'TOTAL STAKED',
  'ESTIMATED REWARDS': 'ESTIMATED REWARDS',
  'Rewards rate is dynamic': 'Rewards rate is dynamic',
  Monthly: 'Monthly',
  Daily: 'Daily',
  'CURRENT REWARDS': 'CURRENT REWARDS',
  'TOTAL REWARDS': 'TOTAL REWARDS',
  'CLAIM REWARDS': 'CLAIM REWARDS',
  'TOTAL SUPPLY': 'TOTAL SUPPLY',
  'OUR PARTNERS and BACKERS': 'OUR PARTNERS & BACKERS',
  'ELONXCAT TEAM': 'ELONXCAT TEAM',
  Founder: 'Founder',
  'Creative Director': 'Creative Director',
  'Community manger': 'Community manger',
  'CONNECT YOUR WALLET': 'CONNECT YOUR WALLET',
  'ENTER PRESALE': 'ENTER PRESALE',
  'CLAIM ELONXCAT': 'CLAIM $ELONXCAT',
  'ROAD MAP': 'ROAD MAP',
  DEVELOPMENT: 'DEVELOPMENT',
  'MEME DOMINATION': 'MEME DOMINATION',
  'FREQUENTLY ASKED QUESTIONS': 'FREQUENTLY ASKED QUESTIONS',
  'What is a presale': 'What is a presale?',
  'What is ELONXCAT': 'What is ELONXCAT?',
  'How do I participate in the presale': 'How do I participate in the presale?',
  'When do I receive my presale tokens': 'When do I receive my presale tokens?',
  'What is ELONXCAT staking': 'What is ELONXCAT staking?',
  'How do I stake my tokens': 'How do I stake my tokens?',
  'I staked my tokens during presale':
    'I staked my tokens during presale, when can I claim them?',
  'STAY CONNECTED': 'STAY CONNECTED',
  'PRIVACY POLICY': 'PRIVACY POLICY',
  'TERMS and CONDITIONS': 'TERMS & CONDITIONS',
  'PRESALE ALLOCATION': 'PRESALE ALLOCATION',
  AIRDROP: 'AIRDROP',
  PROJECTFUNDS: 'PROJECTFUNDS',
  LIQUIDITY: 'LIQUIDITY',
  MARKETING: 'MARKETING',
  EXCHANGES: 'EXCHANGES',
  'ECOSYSTEM FUNDS': 'ECOSYSTEM FUNDS',
  EXPANSION: 'EXPANSION',
  'HELP CENTER': 'HELP CENTER',
  "If it doesn't work well on mobile": "If it doesn't work well on mobile",
  'please try using PC': 'please try using PC',

  texts: {
    txt_1:
      'Our own technology called X supports this multi-chain. X supports chain migration capabilities, allowing ELONXCAT holders to freely traverse the best cryptocurrency chains.',
    txt_2:
      'ELONXCAT is a Meme token built on a multi-chain network spanning BNB Chain, Ethereum, Polygon, Solana, and Tron.',
    txt_3:
      'ELONXCAT strives to unite the cryptocurrency meme community and be a pioneer in multi-chain utility.',
    txt_4:
      'Our goal is to have our name shine on every DEX, every CEX, and everywhere else.',
    txt_5:
      'Leveraging X`s superior bridging technology that graces the five most active blockchains excluding Bitcoin, $ELONXCAT becomes multi-chain seamlessly in just a few clicks, with virtually no gas fees to worry about.',
    txt_6: 'X will continue to expand these multi-chains in the future.',
  },

  tiers: {
    tier_txt_1:
      'The initial presale price is seat $0.0000009, which will change to Tier2 after 20days',
    tier_txt_2:
      'The price of this tier is set at $0.000001 from the presale, which will change to Tier3 after 20 days.',
    tier_txt_3:
      'The price of this tier is set at $0.000005 from the presale, which will change to end price after 20days.',
    tier_txt_4:
      'The presale price of this tier is $0.000009, which will ELONXCAT presale end With this Price.',
    tier_txt_5:
      'ELONXCAT aims to be a top DEX onBSC, focusing on ELONXCAT memecoin with features like token swaps, liquidity pools, and yield farming.',
    tier_txt_6:
      'ELONXCAT Quest blends gaming with crypto, offering quests and puzzles in a blockchain world to earn Lope tokens.',
    tier_txt_7:
      'The ELONXCAT NFT project combines digital art with the ELONXCAT memecoin, offering unique,utility- rich collectibles to deepen community engagement.',
    tier_txt_8:
      'ELONXCAT integrates Al for chat, trading tips, and meme creation, blending finance with creativity in a user-friendly platform.',
    tier_txt_9:
      'ELONXCAT GET TOKEN GAME is a blockchain game. The ELONXCAT ecosystem integrates gaming with tokenomics, allowing players to complete various quests and participate in social to earn tokens. The purpose of this game is not only to showcase our blockchain technology but also to help you become more familiar with our project while earning money through active participation in the game.',
    tier_txt_10:
      'ELONXCAT Coin NFT marketplace has unique collections with special and different features.',
    tier_txt_11:
      'ELONXCAT Coin NFTs will be listed along with the project itself in all global markets as well as the project`s own market.',
    tier_txt_12:
      'The market has an NFT box as well as a large airdrop program that will be activated during the token presale.',
    tier_txt_13:
      'Write SEO optimized blogs, sales emails and more...Achieve superior outcomes in a fraction of the time. At last, a writing tool that you`ll genuinely utilize.',
    tier_txt_14:
      'Visualize what you dream of. Create images from text. Instantly create stunning images using Al Image Generator your tool for artistic innovation.',
    tier_txt_15:
      'Simply choose your asistant and engage with a friendly chatbot to generate ideas, enhance your content, or even bring a smile to your face.',
    tier_txt_16:
      'Ready to write code at the speed of light Effortless coding with Al Code Generator: Instant solutions for your programming needs.',
    tier_txt_17:
      'ElonXCat Swap aims to be a leading decentralized exchange (DEX) platform on the Binance Smart Chain (BSC).',
    tier_txt_18:
      'Customized for the ElonXCat meme coin and its ecosystem, ElonXCat Swap facilitates seamless token swaps, offers liquidity pools, and introduces innovative features such as yield farming and staking. These functionalities aim to enhance liquidity, ensure fair trading, and contribute to the overall growth of the ElonXCat community.',
    tier_txt_19:
      'The distribution of ELONXCAT token rewards will occur at a rate of 708.72 $ELONXCAT per BNB block and will be disbursed over 2 year and will be claimable once clain goes live.',
    tier_txt_20:
      'Use Metamask or Trust Wallet to connect your wallet in seconds.',
    tier_txt_21:
      'Determine how much $ELONXCAT you want to buy. You can purchase with USDT along with BNB , ETH, USDT',
    tier_txt_22:
      'Once the presale concludes, you will be able to claim your $ELONXCAT tokens using the same wallet and chain you used to buy',
    tier_txt_23: 'BIRTH FOR ELONMUSK & X',
    tier_txt_24: 'Comunity building Token creation Social Channels Are Born',
    tier_txt_25: 'KYC & Audit Marketing Pump Presale Begins',
    tier_txt_26:
      'Marketing Initiation CoinMarketCap Application CoinGecko Application ElonXCAT NFT update ElonXCAT Gaming development Staking Update',
    tier_txt_27:
      'First DEX Listings DEXTools Update ElonXCAT Al Update Auto-bum',
    tier_txt_28:
      'Continued Marketing Community Incentives Partnerships with other platforms Grow Holders to 1M+ Holders',
    tier_txt_29:
      'A presale represents a unique opportunity in the cryptocurrency realm where investors are granted the chance to purchase a new token or cryptocurrency before its official release to the broader market. This early access phase allows participants to invest in a project`s potential from its inception.',
    tier_txt_30:
      'ELONXCAT is the truly advanced multichain meme coin and also is real ELONMUSK & X meme project Advanced Web3 Ecosystem : STAKING | DEX | AI | GAME | NFT MARKET.',
    tier_txt_31:
      'You can take part in the presale on BNB Chain. All you need to do is connect your Best Wallet or other wallet to the presale widget above and use BNB, ETH, USDT to reserve $ELONXCAT tokens.',
    tier_txt_32:
      "Once you've completed your presale purchase, you'll be able to use the same wallet to claim your tokens before the first DEX listing. Claim and listing dates will be announced on our social media accounts.",
    tier_txt_33:
      'Staking is the action of sending your tokens to a smart contract and locking them for a defined period of time. This will earn you more $ELONXCAT tokens over time at a dynamic rate. ELONXCAT staking will be available on the BNB network soon. For more information, click on `Staking` in the navigation bar.',
    tier_txt_34:
      'You can stake your $ELONXCAT by heading to the staking page here: https://ELONXCAT/en/staking Staking is only available for $ELONXCAT bought on BNB. To process the gas fees for unstaking and claiming staking rewards you will need $BNB in your wallet',
    tier_txt_35:
      'If you staked your $ELONXCAT , then you`ll need to wait the 14 day minimum vesting period before you can claim them.Staking is only available for $BNB bought on Ethereum. To process the gas fees for unstaking and claiming staking rewards you will need $BNB in your wallet.',
    tier_txt_36:
      'Staking is the action of sending your tokens to a smart contract and locking them for a defined period of time. This will earn you more $ELONXCAT tokens over time at a dynamic rate. ELONXCAT staking will be available on the BNB network soon. For more information, click on `Staking` in the navigation bar.',
    tier_txt_37:
      'You can stake your $ELONXCAT by heading to the staking page here: https://ELONXCAT/en/staking Staking is only available for $ELONXCAT bought on BNB. To process the gas fees for unstaking and claiming staking rewards you will need $BNB in your wallet',
    tier_txt_38:
      'If you staked your $ELONXCAT , then you`ll need to wait the 14 day minimum vesting period before you can claim them. Staking is only available for $BNB bought on Ethereum. To process the gas fees for unstaking and claiming staking rewards you will need $BNB in your wallet',
    tier_txt_39:
      'ELONXCAT Office Panamá 43th Floor, Oceania BusinessPlaza,Tower 2000, Street Punta Colon, Urbanizacion Punta Pacifica,Panama City, Panamá',
    tier_txt_40:
      'Disclaimer : ELONXCAT is a meme coin with speculative value derived only from the universal cultural capital of the ELONXCAT meme The value of cryptocurrencies may go down as well as up. Ape responsibly',
  },

  send_success_message:
    'Your payment has been successfully processed. ElonXCat tokens will be sent to your payment address within 5 minutes.',

  TOKENBURNINGPLAN: 'TOKEN BURNING PLAN',
  OFFICIALELONXCATVIDEO: 'OFFICIAL ELONXCAT VIDEO',
  steps: {
    step_title_1: 'Deposit crypto to your wallet',
    step_title_2: 'Select Network',
    step_title_3: 'Connect Wallet',
    step_title_4: 'Buy Tokens',
    step_title_5: 'Check the EXC$token in your wallet.',
    step_title_6: 'Enjoy your crypto journey with ELONXCAT!',
    step_sub_title_1:
      'Please deposit BNB or USDT into your cryptocurrency wallet (e.g., MetaMask).',
    step_sub_title_2:
      "make sure to switch the wallet's blockchain network to BSC.",
    step_sub_title_3:
      'Find the connect wallet button on presale widget and connect your preferred wallet',
    step_sub_title_4:
      'Enter the amount of BNB or USDT you wish to swap for $EXC and confirm the transaction in your wallet.',
    step_sub_title_5:
      "If you can't see EXC in your wallet, please add the token. The token contract address is 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a",
    step_sub_title_6:
      "The ELONXCAT team will develop ELONXCAT into the best meme coin project. Let's enjoy the crypto journey together with our team!",
    how_to_buy_txt_1: "The 'HOW TO BUY $EXC' video is ready.",
    how_to_buy_txt_2:
      "The Click the button to watch 'HOW TO BUY' in various languages!",
  },
  tokens_burning_plan_description1: 'After listing on the exchange,',
  tokens_burning_plan_description2:
    'Our team gradually burn more than 90% of the tokens',
  tokens_burning_plan_description3:
    'The total supply of the tokens will continue to decrease, and the price will increase',
  tokens_burning_plan_description4:
    'The total supply of the tokens will continue to decrease, and the price will increase',
};
